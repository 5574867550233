<template>
  <div class="container">
    <!--TODO 换成目录和进度条-->
    <aside-profile/>
    <div class="main-box" v-loading="loading">
      <div class="article-item">
        <div class="title">
          {{articleObj.title}}
        </div>
        <div class="statistic-row-1">
          <div class="statistic-item" :title="articleObj.createTime">
            <svg t="1622256843289" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6334" width="20" height="20"><path d="M469.333333 597.333333v-128h85.333334v128h128v85.333334h-128v128h-85.333334v-128H341.333333v-85.333334h128z m384-256V213.333333h-85.333333v42.666667h-85.333333V213.333333H341.333333v42.666667H256V213.333333H170.666667v128h682.666666z m0 85.333334H170.666667v426.666666h682.666666V426.666667z m-85.333333-298.666667h85.333333a85.333333 85.333333 0 0 1 85.333334 85.333333v640a85.333333 85.333333 0 0 1-85.333334 85.333334H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333334V213.333333a85.333333 85.333333 0 0 1 85.333334-85.333333h85.333333V85.333333h85.333333v42.666667h341.333334V85.333333h85.333333v42.666667z" p-id="6335" fill="#4B97E8"></path></svg>
            <span class="text-create">发表于 {{articleObj.createTime ? articleObj.createTime.split(' ')[0] : ''}}</span>
          </div>
          <div class="statistic-item" :title="articleObj.updateTime" v-if="articleObj.updateTime">
            <svg t="1622256764297" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5871" width="20" height="20"><path d="M853.333333 341.333333V213.333333h-85.333333v42.666667h-85.333333V213.333333H341.333333v42.666667H256V213.333333H170.666667v128h682.666666z m0 85.333334H170.666667v426.666666h682.666666V426.666667z m-85.333333-298.666667h85.333333a85.333333 85.333333 0 0 1 85.333334 85.333333v640a85.333333 85.333333 0 0 1-85.333334 85.333334H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333334V213.333333a85.333333 85.333333 0 0 1 85.333334-85.333333h85.333333V85.333333h85.333333v42.666667h341.333334V85.333333h85.333333v42.666667z m-298.666667 537.002667l183.168-183.168 60.330667 60.330666L469.333333 785.664l-158.165333-158.165333 60.330667-60.330667L469.333333 665.002667z" p-id="5872" fill="#419D58"></path></svg>
            <span class="text-update">更新于 {{articleObj.updateTime ? articleObj.updateTime.split(' ')[0] : ''}}</span>
          </div>
          <div class="statistic-item">
            <svg t="1622256972988" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5228" width="20" height="20"><path d="M512 192c-223.328 0-416.896 130.048-512 320 95.104 189.952 288.672 320 512 320s416.864-130.048 512-320c-95.104-189.952-288.672-320-512-320zM764.448 361.696c60.16 38.368 111.136 89.76 149.44 150.304-38.304 60.512-89.28 111.936-149.44 150.304-75.584 48.224-162.88 73.696-252.448 73.696s-176.864-25.472-252.448-73.696c-60.16-38.368-111.136-89.76-149.44-150.304 38.304-60.512 89.28-111.936 149.44-150.304 3.904-2.496 7.872-4.928 11.872-7.296-9.952 27.328-15.424 56.832-15.424 87.584 0 141.376 114.624 256 256 256s256-114.624 256-256c0-30.784-5.44-60.256-15.424-87.584 3.968 2.368 7.936 4.8 11.872 7.296l0 0zM512 416c0 53.024-42.976 96-96 96s-96-42.976-96-96 42.976-96 96-96 96 42.976 96 96z" p-id="5229" fill="#707070"></path></svg>
            <span class="text-count">阅读次数：{{articleObj.visitCount}}</span>
          </div>
        </div>
        <div class="statistic-row-2">
          <div class="statistic-item">
            <svg t="1622257135721" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7008" width="20" height="20"><path d="M128 469.333333v341.333334h768v-341.333334H128z m0-85.333333h768V298.666667h-384.213333c-30.677333-0.170667-50.602667-14.506667-72.106667-41.088-2.944-3.669333-12.373333-15.914667-13.781333-17.749334C409.898667 219.733333 400.384 213.333333 384 213.333333H128v170.666667z m768-170.666667a85.333333 85.333333 0 0 1 85.333333 85.333334v512a85.333333 85.333333 0 0 1-85.333333 85.333333H128a85.333333 85.333333 0 0 1-85.333333-85.333333V213.333333a85.333333 85.333333 0 0 1 85.333333-85.333333h256c47.786667 0 78.208 20.266667 108.757333 58.837333 2.048 2.56 11.136 14.378667 13.354667 17.152 6.741333 8.32 8.106667 9.344 5.973333 9.344H896z" p-id="7009" fill="#A8A558"></path></svg>
            <span class="text-category">分类：{{articleObj.categoryList.join('、')}}</span>
          </div>
        </div>
        <div class="content">
          <Markdown :content="articleObj.content ? articleObj.content : ''"/>
        </div>

        <div class="article-link-tag">
          <router-link  v-if="articleObj.prevArticle" class="left-link" :to="`/article/${articleObj.prevArticle.id}`" replace>
            <el-icon class="el-icon-arrow-left"/>
            {{articleObj.prevArticle.title}}
          </router-link>
          <div v-else>
            &nbsp;
          </div>
          <div class="tags">
            <div class="tag-item" v-for="item in articleObj.tagList" :key="item">
              <svg t="1622267795286" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5037" width="16" height="16"><path d="M329.142857 256q0-30.285714-21.428571-51.714286T256 182.857143t-51.714286 21.428571T182.857143 256t21.428571 51.714286T256 329.142857t51.714286-21.428571T329.142857 256z m609.714286 329.142857q0 30.285714-21.142857 51.428572l-280.571429 281.142857q-22.285714 21.142857-52 21.142857-30.285714 0-51.428571-21.142857L125.142857 508.571429q-21.714286-21.142857-36.857143-57.714286T73.142857 384V146.285714q0-29.714286 21.714286-51.428571t51.428571-21.714286h237.714286q30.285714 0 66.857143 15.142857T509.142857 125.142857l408.571429 408q21.142857 22.285714 21.142857 52z" p-id="5038" fill="#686767"></path></svg>
              <span>{{item}}</span>
            </div>
          </div>
          <router-link v-if="articleObj.nextArticle" class="right-link" :to="`/article/${articleObj.nextArticle.id}`" replace>
            {{articleObj.nextArticle.title}}
            <el-icon class="el-icon-arrow-right"/>
          </router-link>
          <div v-else>
            &nbsp;
          </div>
        </div>
      </div>
      <div class="statement">
        <p>作者：城北郑工</p>
        <p>声明：本博客采用许可协议 <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.zh" target="_blank">CC BY-NC-SA 4.0</a> 转载请注明出处！</p>
      </div>
      <div class="reward">
        <div class="left">
          <span class="welcome">欢迎请我喝咖啡~</span>
          <!--支付宝-->
          <div class="icon">
            <el-popover
              placement="top"
              width="200"
              trigger="hover">
              <img src="../assets/alipay.jpg" alt="alipay" style="width: 200px;height: 200px">
              <div slot="reference">
                <svg t="1622269910453" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1185" width="24" height="24"><path d="M1023.795 853.64v6.348a163.807 163.807 0 0 1-163.807 163.807h-696.18A163.807 163.807 0 0 1 0 859.988v-696.18A163.807 163.807 0 0 1 163.807 0h696.181a163.807 163.807 0 0 1 163.807 163.807V853.64z" fill="#009FE9" p-id="1186"></path><path d="M844.836 648.267c-40.952-14.333-95.623-34.809-156.846-57.128a949.058 949.058 0 0 0 90.094-222.573H573.325V307.14h245.711v-43.41l-245.71 2.458V143.33H472.173c-18.223 0-21.704 20.476-21.704 20.476v102.38H204.759v40.952h245.71v61.427H245.712v40.952h409.518a805.522 805.522 0 0 1-64.909 148.246c-128.384-42.795-266.186-77.604-354.233-55.08a213.564 213.564 0 0 0-112.003 63.27c-95.418 116.917-26.21 294.034 175.274 294.034 119.989 0 236.087-67.366 325.771-177.73 134.322 65.932 398.666 176.297 398.666 176.297V701.3s-32.352-4.095-178.96-53.033z m-563.702 144.97c-158.893 0-204.759-124.699-126.336-194.112a191.86 191.86 0 0 1 90.913-46.276c93.575-10.238 189.811 35.629 293.624 86.614-74.941 94.598-166.674 153.774-258.2 153.774z" fill="#FFFFFF" p-id="1187"></path></svg>
              </div>
            </el-popover>
          </div>
          <!--微信支付-->
          <div class="icon">
            <el-popover
              placement="top"
              width="200"
              trigger="hover">
              <img src="../assets/wechatpay.jpg" alt="wechatpay" style="width: 200px;height: 200px">
              <div slot="reference">
                <svg t="1622269952194" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="914" width="24" height="24"><path d="M380.8 636.8c-3.2 3.2-9.6 3.2-16 3.2-12.8 0-22.4-6.4-28.8-19.2l-3.2-6.4-91.2-209.6c0-3.2-3.2-6.4-3.2-6.4 0-9.6 6.4-16 16-16 3.2 0 6.4 0 9.6 3.2l107.2 78.4c12.8 9.6 28.8 9.6 44.8 6.4l512-233.6C836.8 128 684.8 54.4 513.6 54.4 235.2 54.4 8 248 8 488c0 129.6 70.4 249.6 174.4 329.6 12.8 9.6 16 25.6 12.8 38.4L171.2 944c0 3.2-3.2 9.6-3.2 12.8 0 9.6 6.4 16 16 16 3.2 0 6.4 0 9.6-3.2l110.4-67.2c9.6-6.4 16-9.6 25.6-9.6 6.4 0 9.6 0 16 3.2 54.4 16 110.4 25.6 164.8 25.6 278.4 0 505.6-196.8 505.6-433.6 0-72-22.4-142.4-57.6-203.2L377.6 633.6l3.2 3.2z" fill="#7CD138" p-id="915"></path></svg>
              </div>
            </el-popover>
          </div>
        </div>
        <div class="right">
          <span class="share">分享本文</span>
          <div class="icon" @click="copy2share">
            <svg t="1622269802653" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7039" width="24" height="24"><path d="M794.946413 676.840727a174.519642 174.519642 0 0 0-109.882737 38.782143L386.810531 542.026612a166.209183 166.209183 0 0 0 0-67.407057l297.32976-174.519642a174.519642 174.519642 0 0 0 109.882738 38.782143A170.826104 170.826104 0 0 0 967.619286 168.979336a173.596257 173.596257 0 0 0-346.26913 0 166.209183 166.209183 0 0 0 0 34.165221L327.713933 377.664198a174.519642 174.519642 0 0 0-109.882737-38.782142 168.979336 168.979336 0 1 0 0 338.882055 174.519642 174.519642 0 0 0 109.882737-38.782142L625.043693 812.578226a166.209183 166.209183 0 0 0 0 34.165221 173.596257 173.596257 0 1 0 169.90272-169.90272z" p-id="7040" fill="#E39292"></path></svg>
          </div>
        </div>
      </div>
      <div class="comment-list-box" v-loading="commentLoading">
        <div class="header">{{commentList.length}}条评论</div>
        <div class="comment-item" v-for="item in commentList" :key="item.id">
          <img :src="item.commenterAvatar" alt="avatar" class="avatar" @error="handleAvatarError">
          <div class="content">
            <div>
              <span>{{item.commenterName}}</span>
              <span style="margin-left: 20px">{{item.createTime}}</span>
            </div>
            <p>
              {{item.content}}
            </p>
          </div>
        </div>
      </div>
      <div class="comment-box" v-loading="submitting">
        <div class="header">
          发表评论
        </div>
        <el-form ref="form" :rules="rules" :model="commentForm" label-width="60px" class="comment-form">
          <el-form-item prop="content" class="reset-margin">
            <el-input v-model="commentForm.content" type="textarea" :autosize="{minRows: 4, maxRows: 8}"
                      resize="none" class="text"
                      placeholder="如果您对本文有什么想法，欢迎留言~"></el-input>
          </el-form-item>
          <div style="display: flex">
            <el-form-item label="名称" prop="commenterName">
              <el-input v-model="commentForm.commenterName"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="commenterEmail">
              <el-input v-model="commentForm.commenterEmail" type="email" placeholder="用于回复交流"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="submit-btn" @click="submit">提交审核</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import AsideProfile from '@/components/AsideProfile'
import Markdown from '@/components/Markdown'
import { getArticleById, addComment, getComment } from '@/api/article'
// eslint-disable-next-line no-unused-vars
import { Loading } from 'element-ui'
import { scrollTo } from '@/utils/scroll-to'

export default {
  name: 'ArticleDetail',
  components: {
    AsideProfile,
    Markdown
  },
  data () {
    const checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('邮箱不能为空'))
      }
      if (mailReg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的邮箱格式'))
      }
    }
    return {
      articleObj: {
        id: undefined,
        title: undefined,
        content: undefined,
        createTime: undefined,
        updateTime: undefined,
        visitCount: 0,
        categoryList: [],
        tagList: [],
        prevArticle: {
          id: undefined,
          title: undefined
        },
        nextArticle: {
          id: undefined,
          title: undefined
        }
      },
      commentList: [],
      commentForm: {
        content: undefined,
        commenterName: undefined,
        commenterEmail: undefined
      },
      rules: {
        content: [
          { required: true, message: '请填写评论内容', trigger: 'blur' },
          { min: 3, max: 5000, message: '长度在 3 到 5000 个字符', trigger: 'blur' }
        ],
        commenterName: [
          { required: true, message: '请填写输入姓名', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
        ],
        commenterEmail: [
          { required: true, message: '请填写输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ]
      },
      loading: false,
      commentLoading: false,
      submitting: false
    }
  },
  watch: {
    '$route' (to, from) {
      // 当使用路由参数时，同名路由之间跳转时，原来的组件实例会被复用，这也意味着组件的生命周期钩子不会再被调用。
      // 详见：https://router.vuejs.org/zh/guide/essentials/dynamic-matching.html#%E5%93%8D%E5%BA%94%E8%B7%AF%E7%94%B1%E5%8F%82%E6%95%B0%E7%9A%84%E5%8F%98%E5%8C%96
      // 点击上一篇/下一篇文章时路由参数变了但是不会触发created钩子函数
      // console.log(from, '-->', to)
      if (to.name === from.name && to.path !== from.path) {
        this.getArticle()
      }
    }
  },
  created () {
    this.getArticle()
  },
  // beforeRouteUpdate (to, from, next) {
  //   if (to.name === from.name && to.path !== from.path) {
  //     this.getArticle()
  //     next() // don't forget to call next()
  //   }
  // },
  methods: {
    getArticle () {
      this.loading = true
      this.commentLoading = true
      const articleId = this.$route.params.id
      getArticleById(articleId).then(res => {
        this.articleObj = res.data
      }).finally(() => {
        this.loading = false
      })
      getComment(articleId).then(res => {
        this.commentList = res.data
      }).finally(() => {
        this.commentLoading = false
      })
      scrollTo(0, 800)
    },
    copy2share () {
      const copyInput = document.createElement('input')
      copyInput.value = location.href
      // copyInput.setAttribute('readonly', 'readonly');//避免ios端聚焦引起的白屏抖动
      document.body.appendChild(copyInput)// 插入body
      copyInput.select()// 选择对象
      // copyInput.setSelectionRange(0, link.length);//ios端使用setSelectionRange避免选择不全问题
      document.execCommand('Copy')// 执行复制命令
      copyInput.remove()// 移除

      this.$message.success('已复制链接')
    },
    submit () {
      if (this.submitting) return

      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message.error('请填写完整表单')
          return false
        }
        this.submitting = true
        // 开启loading
        // const loadingInstance = Loading.service({})

        // 提交评论到后端
        const comment = { ...this.commentForm }
        comment.articleId = this.$route.params.id
        addComment(comment).then(res => {
          this.$message.success(res.msg)
          this.resetFields()
        }).finally(() => {
          this.submitting = false
          /* this.$nextTick(() => {
            loadingInstance.close()
          }) */
        })
      })
    },
    handleAvatarError (e) {
      // 图片加载失败时使用默认图片
      e.target.src = require('../assets/default-avatar.png')
    },
    resetFields () {
      this.commentForm = {
        content: undefined,
        commenterName: undefined,
        commenterEmail: undefined
      }
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  justify-content: space-between;

  .main-box {
    width: 770px;

    .article-item {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 20px;

      .title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }

      .statistic-row-1, .statistic-row-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        .statistic-item {
          display: flex;
          align-items: center;
          margin: 0 10px;
          span {
            margin-left: 5px;
            font-size: 14px;
          }

          .text-create {
            color: #4B97E8;
          }

          .text-update {
            color: #419D58;
          }

          .text-count {
            color: #707070;
          }

          .text-category {
            color: #A8A558;
          }
        }
      }

      .content {
        margin: 30px 0;
      }

      .article-link-tag {
        display: grid;
        grid-template-columns: 30% 40% 30%;
        grid-template-rows: 30px;

        .left-link, .right-link {
          color: #686767;
          line-height: 33px;
          text-decoration: none;
        }

        .left-link {
          text-align: left;
        }

        .right-link {
          text-align: right;
        }

        .tags {
          display: flex;
          justify-content: center;

          .tag-item {
            display: flex;
            align-items: center;
            margin: 0 5px;
            color: #686767;
            height: 30px;
            border-bottom: 1px solid #686767;

            &:hover {
              color: #2F2E2E;
              border-bottom: 1px solid #2F2E2E;
            }

            span {
              margin-left: 3px;
            }
          }
        }

      }

    }

    .statement {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 20px;
      margin-top: 15px;

      color: #333333;

      p {
        margin: 5px 0;
      }
    }

    .reward {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 20px;
      margin-top: 15px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .left, .right {
        display: flex;
        align-items: center;
        font-weight: bold;

        .icon {
          margin-left: 5px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }

    }

    .comment-list-box {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 20px;
      margin-top: 15px;

      .comment-item {
        display: flex;
        margin: 40px 0;

        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          overflow: hidden;
          flex-shrink: 0;
        }

        .content {
          margin-left: 20px;

          p {
            margin-top: 10px;
          }
        }
      }
    }

    .comment-box {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 20px;
      margin-top: 15px;

      .header {
        font-weight: bold;
      }

      .text {
        margin-top: 10px;
      }

      .comment-form {
        margin-top: 10px;

        .reset-margin /deep/ .el-form-item__content {
          margin-left: 0 !important;
        }
      }

      .submit-btn {
        background-color: #3DBCF2;
        border-color: unset;
      }
    }
  }

}
</style>
